import { EditorReadyFn } from 'yoshi-flow-editor-runtime';
import { EditorScriptFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/EditorScript';
import { BM, BM_CREATE_ALBUM, BM_MANAGE_ALBUMS_STORE } from './consts/routes';
import { EditorSDK, EditorReadyOptions } from '@wix/platform-editor-sdk/lib';
import { checkIfHasBusinessPackage } from './services/gogApi';
import _ from 'lodash';
import actions from './consts/editorActions';
import { createManifest } from './manifest';
import { id as gogWidgetId } from './components/AlbumsGOGOOI/.component.json';

let appDefinitionId: string;
let sdk: EditorSDK;
let t: any;
let instance: string;
let hasBusinessPackage: boolean = false;
let shouldAddManageGFPP: boolean = false;

export const editorReady: EditorReadyFn = async (
  _editorSDK: EditorSDK,
  _appDefinitionId: string,
  platformOptions: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
) => {
  const { reportError, fedopsLogger, translations, getExperiments } = flowAPI;
  appDefinitionId = _appDefinitionId;
  sdk = _editorSDK;
  t = translations.t;
  const experimentsContext = await getExperiments();

  shouldAddManageGFPP =
    experimentsContext?.get('specs.albums.addManageGFPP') === 'true';

  const locale = await sdk.environment.getLocale(appDefinitionId);
  const metaSiteId = await sdk.info.getMetaSiteId(appDefinitionId);
  instance = await sdk.document.info.getAppInstance(appDefinitionId);

  const data = await checkIfHasBusinessPackage(metaSiteId, locale, instance);
  hasBusinessPackage = data ? data.result : false;

  fedopsLogger.appLoadStarted();
  try {
    if (platformOptions.firstInstall) {
      fedopsLogger.interactionStarted('install');
      // Place for your app initialization here. (Add pages to editor platform, init configs, etc);
      console.log('App was installed ✅');
      fedopsLogger.interactionEnded('install');
    }
  } catch (e) {
    reportError(e);
  }

  fedopsLogger.appLoaded();
};

export const getAppManifest = () => {
  const manifest = createManifest({
    t,
    hasBusinessPackage,
    shouldAddManageGFPP,
  });
  return manifest;
};

const getComponentRef = async (applicationId: number) => {
  const comps =
    (await sdk.tpa.app.getAllCompsByApplicationId(
      appDefinitionId,
      applicationId,
    )) || [];
  const albumsSectionComponent = comps[0] || {};
  return sdk.components.getById(appDefinitionId, {
    id: albumsSectionComponent.id,
  });
};

const getSettingsUrl = (appData: any) => {
  if (appData.settingsUrl?.indexOf('albums.wix.com') > -1) {
    if (appData.widgets[gogWidgetId]?.settings?.urlV2) {
      return appData.widgets[gogWidgetId]?.settings?.urlV2;
    }
  }
  return appData.settingsUrl;
};

const openAppSettings = async () => {
  const appData: any = await sdk.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId,
  );

  const settingsUrl = getSettingsUrl(appData);
  const componentRef = await getComponentRef(appData.applicationId);
  const photoAlbumsPage = await sdk.components.getPage(appDefinitionId, {
    componentRef,
  });
  await sdk.document.pages.navigateTo(appDefinitionId, {
    pageRef: photoAlbumsPage,
  });
  return sdk.editor.openComponentPanel(appDefinitionId, {
    url:
      settingsUrl +
      `?instance=${instance}&applicationId=${appData.applicationId}&compId=${componentRef.id}`,
    type: sdk.editor.PanelType.Settings,
    componentRef,
    width: 404,
    height: 528,
    title: 'Photo Albums',
  });
};

export const onEvent = async ({ eventType, eventPayload }: any) => {
  if (eventType === 'appActionClicked') {
    switch (eventPayload.actionId) {
      case actions.CREATE_ALBUM_ACTION:
        sdk.editor.openDashboardPanel(appDefinitionId, {
          url: BM_CREATE_ALBUM,
          closeOtherPanels: true,
        });
        break;
      case actions.MANAGE_ALBUMS_ACTION:
        sdk.editor.openDashboardPanel(appDefinitionId, {
          url: BM,
          closeOtherPanels: true,
        });
        break;
      case actions.MANAGE_ALBUMS_STORE_ACTION:
        sdk.editor.openDashboardPanel(appDefinitionId, {
          url: BM_MANAGE_ALBUMS_STORE,
          closeOtherPanels: true,
        });
        break;
      case actions.CUSTOMIZE_ALBUMS_PAGE_ACTION:
        await openAppSettings();
        break;
      default:
        break;
    }
  }

  if (eventType === 'controllerGfppClicked') {
    switch (eventPayload.id) {
      case actions.MANAGE_ALBUMS_ACTION:
        sdk.editor
          .openDashboardPanel(appDefinitionId, {
            url: BM,
            closeOtherPanels: true,
          })
          .then(() => {
            sdk.document.tpa.app.refreshApp(appDefinitionId);
          });
        break;
      case actions.CUSTOMIZE_ALBUMS_PAGE_ACTION:
        await openAppSettings();
        break;
      default:
        break;
    }
  }
};
